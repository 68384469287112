
.card_pet{
    text-align: center;
}
.pet_name{
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 0;
}
.pet_status{
    font-size: 2rem;
    font-weight: bold;
}
.pet_status i{
    color: yellow; 
}