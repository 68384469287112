:root {
    --danger-pets: #dc3545;
    --gray-pets: #C8C7C7;
  }
  
*{
    font-family: 'Nunito', sans-serif;
}
a{
    text-decoration: none !important;
}

.message-error{
    font-weight:"bold";
    color:var(--danger-pets);      
}
.text-mobile{
    font-size: 1rem !important;
    line-height: normal !important;
}
@media (max-width: 500px) {
    .link img{
        height: 40px!important;
        width: 40px!important;
    }
    .link i{
        font-size: 2rem !important;
    }
    .text-mobile{
        font-size: 0.8rem !important;
        line-height: 1 !important;
    }
}
.autocompleted_input{
    height: 90px;
}
.selectable{
    cursor: pointer;
}
.selectable:hover{
    opacity: .9;
}

.spinner-border{
    --bs-spinner-width: 3rem !important;
--bs-spinner-height: 3rem !important;
}

/* navbar mobile */
#offcanvasNavbar-expand{
    width: 70% !important;
}

/* tables */
table thead{
    background:#CB0E1B;
    color:#fff;
}

thead th{
    border:none;
    margin: 5px;
}
td> div>p {
    margin: 0;
}
th>div>p{
    margin: 5px;
}

tr td{
    background-color: #fff !important;
}

/* botones */
.btn-danger{
background-color: #CB0E1B !important;
}

.btn-danger:hover{
    background-color: #e61725 !important;
}

/* paginacion */
.page-link{
    color: #000 !important;
}

.page-item.active> .page-link{
    background-color: #CB0E1B !important;
    border-color: #CB0E1B !important;
    color: #fff !important;
}

