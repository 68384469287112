.table{
    font-size: .7rem !important;
}
td{
    text-align: center;
}
.col-1{
    min-width: 50px !important;
}
.col-2{
    min-width: 120px !important;
}

.col-3{
    min-width: 150px !important;
}

.col-4{
    min-width: 120px !important;
}