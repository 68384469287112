.info_owner_entry{
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_owner_title{
    background-color: rgb(61, 60, 60);
    color: #fff;
}

.info_owner_text{
    background-color: rgb(227, 220, 220);
}