.link{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 15px;
    font-weight: 500;

}
.link:hover{
    color:#CB0E1B !important;
}
.link i{
    font-size: 3rem;
}
.menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}