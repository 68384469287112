.linkp{
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    height: 50px;
    justify-content: center;
}
.linkp:hover{
    color:#CB0E1B !important;
}
.linkp i{
    font-size: 3rem;
}
.menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}