.banner{
    width: 100%;
    text-align: center;
}
.banner img{
    min-width: 270px;
    max-width: 50%;    
}

.banner-publicity{
    width: 100%;
    text-align: center;
}

.banner-publicity img{
    height: 200px;
    object-fit: cover;
}